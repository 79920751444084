// Bootstrap Overrides

$body-color:                        #41505f;
$body-bg:                           #fbfcfe;
$input-bg:                          #fff;

$gray-100:                          #f8f9fc;
$gray-200:                          #f3f5f9;
$gray-300:                          #e2e5ec;
$gray-400:                          #ccd2da;
$gray-500:                          #9ea7b1;
$gray-600:                          #6e7985;
$gray-700:                          #41505f;
$gray-800:                          #313c47;
$gray-900:                          #212830;

// Theme Colors
$color-ui-01:                       #506fd9;
$color-ui-02:                       #85b6ff;
$color-ui-03:                       #4c5366;
$color-ui-04:                       #e9ebf1;

$color-tx-01:                       $gray-900;
$color-tx-02:                       $gray-700;
$color-tx-03:                       $gray-600;

$primary:                           $color-ui-01;
$success:                           #0cb785;
$teal:                              #33d685;
$pink:                              #ea4c89;

// Paleta UA
// 
$ua1:                               #009ace;
$ua2:                               #44d62c;
$ua3:                               #ffaa4d;
$ua4:                               #ff7276;
$ua5:                               #ea27c2;
$ua6:                               #00b796;
$ua7:                               #fdd635;
$ua8:                               #bd53ea;
$ua9:                               #8a69d4;
// Dark Theme Colors
$dark-primary:                      #141926;
$dark-highlight:                    #192030;

// Borders
$border-radius:                     0.25rem;
$border-color-dark:                 lighten($dark-primary, 8%);

// Buttons
$input-btn-padding-y:               .406rem;


// Fonts
$ff-primary:                        'Open Sans', sans-serif;
$ff-secondary:                      'Archivo', sans-serif;
$ff-numerals:                       'Inter', sans-serif;

$font-family-base:                  $ff-primary;

$font-weight-base:                  400;
$font-weight-light:                 300;
$font-weight-medium:                500;
$font-weight-semibold:              600;
$font-weight-bold:                  700;

$font-size-base:                    0.875rem;
$font-size-sm:                      0.8125rem;
$font-size-xs:                      0.75rem;
$font-size-md:                      0.938rem;

// Links
$link-decoration:                   none;

// Size
$height-header:                     72px;
$height-base:                       36px;
$sidebar-width:                     250px;

// Shadows
$box-shadow:                        0 .5rem 1rem rgba($gray-900, .15) !default;
$box-shadow-sm:                     0 .125rem .25rem rgba($gray-900, .075) !default;
$box-shadow-lg:                     0 1rem 3rem rgba($gray-900, .175) !default;
$box-shadow-inset:                  inset 0 1px 2px rgba($gray-900, .075) !default;
