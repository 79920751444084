.searchInputs {
    width: 450px;
    display: flex;
    background-color: white;
    border-radius: 4px;
    border: 2px solid;
    border-color: #7a1111;

}

.searchInputs input {
    background-color:  white;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0%; 
    border-bottom-left-radius: 0%;
    font-size: 18px;
    padding: 15px;
    height: 20px;
    width: 500px;
}

.search button {
    margin-top:0px;
    display: flex;
    padding: 8.5px 15px;
    margin-left: 150px;
    min-width: 82px;
    border-radius: 5px;
    border: 1px solid;
    border-color: #f6f6f6;
    transition: all 0.2s ease;
    cursor: pointer;
    box-shadow: 0px 12px 5px -8px rgba(123, 231, 166, 0.2);
    color: rgb(254, 248, 254);
    background-color:  rgba(18, 31, 73, 0.282);
    text-align: center;
    transition-duration: 0.4s;

}

.search button:hover {
    background-color: #2a94e0dd; /* Green */
    color: rgb(0, 0, 0);
  }



