.card-description-link:hover {
    text-decoration: underline;
  }

  .small-title {
    font-size: 17px; /* o el tamaño que prefieras */
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Ajusta esto para controlar el espacio entre tarjetas */
    gap: -3px; /* Espacio entre tarjetas */
  }
  