.chart-back {
    background-color: var(--color-slider) !important;
    cursor: default;
}

.card-text {
    color: var(--color-text-slider);
    text-decoration: none;
}

.card-text:hover {
    color: var(--color-text-slider);
    text-decoration: none;
}

/* Estilos por defecto */
.chart-card {
    width: 100%;
    height: 500px;
  }
  
  /* Estilos para gráficos de barra */
  .chart-card.bar-chart,
  .chart-card.stacked-bar-chart {
    width: 48%;
    height: 300px;
  }
  
  /* Estilos para gráficos circulares */
  .chart-card.doughnut-chart,
  .chart-card.pie-chart,
  .chart-card.radar-chart,
  .chart-card.polar-chart{
    width: 23.4%;
    height: 300px;
  }

  .chart-card.doughnut-chart-inte,
  .chart-card.polar-chart-inte,
  .chart-card.radar-chart-inte {
    width: 48.9%;
    height: 300px;
  }

  .full-width-chart {
    width: 100%; 
    min-height: 500px; 
  }

  .chart-title {
    font-size: 20px; 
    font-weight: bold; 
    margin-bottom: 20px; 
  }
  
  
  /* Estilos para pantallas de 1200px o menos */
  @media (max-width: 1255px) {
    .chart-card.bar-chart,
    .chart-card.stacked-bar-chart,
    .chart-card.polar-chart {
      width: 94%;
    }
  
    .chart-card.doughnut-chart,
    .chart-card.pie-chart,
    .chart-card.radar-chart,
    .chart-card.polar-chart{
      width: 46%;
    }
  }
  
  /* Estilos para pantallas de 600px o menos */
  @media (max-width: 650px) {
    .chart-card.doughnut-chart,
    .chart-card.pie-chart,
    .chart-card.radar-chart,.chart-card.bar-chart,
    .chart-card.stacked-bar-chart,
    .chart-card.polar-chart{
      width: 95%;
    }
  }



  @media (max-width: 1800px) {
    .chart-card.doughnut-chart-inte,
  .chart-card.polar-chart-inte,
  .chart-card.radar-chart-inte {
    width: 48%;
    height: 300px;  
    }
  }
  @media (max-width: 1200px) {
    .chart-card.doughnut-chart-inte,
  .chart-card.polar-chart-inte,
  .chart-card.radar-chart-inte {
    width: 47%;
    height: 300px;  
    }
  }

  @media (max-width: 613px) {
    .chart-card.doughnut-chart-inte,
  .chart-card.polar-chart-inte,
  .chart-card.radar-chart-inte {
    width: 95%;
    height: 300px;  
    }
  }

  .download-btn {
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 12px;
    cursor: pointer;
  }
  
  .download-btn i {
    font-size: 14px;
  }
  