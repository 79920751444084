
.bg {
    background-image: "./container-media@1x.png";
    height: 50vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
    .searchbar{
    margin-bottom: auto;
    margin-top: auto;
    height: 60px;
    background-color: #353b48;
    border-radius: 30px;
    padding: 10px;
    }

    .search_input{
    color: white;
    border: 0;
    outline: 0;
    background: none;
    width: 0;
    caret-color:transparent;
    line-height: 40px;
    transition: width 0.4s linear;
    }

    .searchbar:hover > .search_input{
    padding: 0 10px;
    width: 450px;
    caret-color:red;
    transition: width 0.4s linear;
    }

    .searchbar:hover > .search_icon{
    background: white;
    color: #e74c3c;
    }

    .search_icon{
    height: 40px;
    width: 40px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color:white;
    text-decoration:none;
    }

    .space {
        width: 4px;
        height: auto;
        display: inline-block;
      }
      

      .yt-lite {
        background-color: #000;
        position: relative;
        display: block;
        contain: content;
        background-position: center center;
        background-size: cover;
        cursor: pointer;
    }
    
    /* gradient */
    .yt-lite::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
        background-position: top;
        background-repeat: repeat-x;
        height: 60px;
        padding-bottom: 50px;
        width: 100%;
        transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    }
    
    /* responsive iframe with a 16:9 aspect ratio
        thanks https://css-tricks.com/responsive-iframes/
    */
    .yt-lite::after {
        content: "";
        display: block;
        padding-bottom: calc(100% / (16 / 9));
    }
    .yt-lite > iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    
    /* play button */
    .yt-lite > .lty-playbtn {
        width: 70px;
        height: 46px;
        background-color: #212121;
        z-index: 1;
        opacity: 0.8;
        border-radius: 14%; /* TODO: Consider replacing this with YT's actual svg. Eh. */
        transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    }
    .yt-lite:hover > .lty-playbtn {
        background-color: #f00;
        opacity: 1;
    }
    /* play button triangle */
    .yt-lite > .lty-playbtn:before {
        content: '';
        border-style: solid;
        border-width: 11px 0 11px 19px;
        border-color: transparent transparent transparent #fff;
    }
    
    .yt-lite > .lty-playbtn,
    .yt-lite > .lty-playbtn:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
    
    /* Post-click styles */
    .yt-lite.lyt-activated {
        cursor: unset;
    }
    .yt-lite.lyt-activated::before,
    .yt-lite.lyt-activated > .lty-playbtn {
        opacity: 0;
        pointer-events: none;
    }

.main-card {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    padding: 10px;
    background: rgba(224, 224, 224, 0.6);
    border-radius: 8px;
    height: 688px; 
    gap: 16px; 
    box-sizing: border-box; 
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.main-card-proyecto {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    padding: 16px;
    background: rgba(224, 224, 224, 0.6);  /* Un gris clarito */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 
    gap: 16px; 
    box-sizing: border-box; 
    overflow-y: auto;
    overflow-x: hidden;
}


.main-card::-webkit-scrollbar {
    width: 8px; 
}

.main-card::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2); 
    border-radius: 10px; 
}

.main-card::-webkit-scrollbar-track {
    background: transparent; 
}

.main-card::-webkit-scrollbar-button {
    display: none; 
}

.cardhome {
    background: #fff;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    min-height: 320px; 
}


.card-container-small {
    width: 25%; 
}

.card-container-large {
    width: 46%; 
}

.out-margin {
    margin-bottom: -200px
}


.carousel {
    position: relative;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 5%;
    height: 10%;
    position: absolute; 
    top: 50%; 
    transform: translateY(450%);
  }

  .video-iframe {
    width: 100%;
    height: 360px; 
  }

  .button-container {
    display: flex;
    justify-content: center; /* Centra los botones horizontalmente */
    flex-wrap: wrap; /* Permite que los botones se ajusten si hay falta de espacio */
    width: 100%; /* Asegura que el contenedor no se salga del ancho de la card */
    overflow: hidden; /* Evita el desbordamiento del contenedor */
}

.custom-button {
    background-color: #DA291C !important;
    color: white;
    border: 1px solid #DA291C !important;
    margin: 5px;
    padding: 18px 24px !important;
    border-radius: 4px;
    transition: background-color 0.3s, border-color 0.3s;
    width: 150px; /* Define un ancho fijo para los botones */
    height: 60px; /* Define una altura fija para los botones */
    text-align: center; /* Asegura que el texto esté centrado */
}

.custom-button:hover {
    background-color: #c62828 !important; 
    border-color: #c62828 !important; /* Cambia el borde al mismo color en hover */
    color: white;
}

.icon-red {
    color: #DA291C; /* Color rojo */
}