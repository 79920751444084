.DescargaPlanillas-ListaPlanillasItem {
    background-color: #ff6161;
    border-top: .5px solid #000;
    /* border-left: 1px solid #000; */
    /* border-right: 1px solid #000; */
    flex-direction: row;
    justify-content: space-between;
    display: flex;
}

.DescargaPlanillas-TopBarListaPlanillas {
    color: #fff;
    width: calc(100% - 20px);
    flex-direction: row;
    justify-content: space-around;
    display: flex;
    margin-left: 10px;
}

.DescargaPlanillas-ListaPlanillas {
    color: #000003;
    height: auto;
    max-height: 350px;
    min-height: 300px;
    width: auto;
    max-width: 700px;
    min-width: 500px;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    margin-bottom: 25px;
    margin-left: 10px;
    margin-right: 10px;
    overflow-y: auto;
}

.DescargaPlanillas-DivisorContenido {
    color: #000;
    width: 70%;
    display: flex;
    border-left: 1px black solid;
    border-right: 1px black solid;
    flex-direction: row;
    justify-content: flex-start;
}

.DescargaPlanillas-style-button {
    /* height: 50px; */
    color: #fff;
    width: 50%;
    background-color: #970000;
    border: 1px solid #e5e5e5;
    /* border-radius: 10px; */
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
}

.DescargaPlanillas-style-button:active {
    /* height: 50px; */
    color: #fff;
    width: 50%;
    background-color: #970000;
    border: 1px solid #e5e5e5;
    /* border-radius: 10px; */
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
}