.SubirCSV-form {
    /* width: 390px; */
    width: 40%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SubirCSV-csvInput {
    color: var(--color-text-background);
    position: relative;
    margin-top: 60px;
    width: 320px;
    margin-left: 30px;
    overflow-x: hidden;
}

.SubirCSV-csvInput::after {
    content: attr(data-file-name);
    padding-top: 15px;
    text-align: center;
    font-size: 18px;
    display: block;
    color: black;
}

.SubirCSV-csvInput::-webkit-file-upload-button {
    border-radius: 50px;
    background-color: #f91616;
    border: 1px solid #f91616;
    color: white;
    font-size: 25px;
    width: 100%;
    display: inline-block;
}

.SubirCSV-uploadButton{
    margin-top: 60px;
    margin-left: 30px;
    white-space:inherit;
}

.SubirCSV-salirButton{
    margin-top: 60px;
    margin-left: 40px;
    white-space:inherit;
}

.SubirCSV-Usuario {
    position: relative;
    left: 41.67px;
}

.SubirCSV-Contraseña {
    position: relative;
    left: 10px;
}

.SubirCSV-button {
    height: 50px;
    color: #fff;
    background-color: #f91616;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    font-size: 25px;
    padding-left: 15px;
    padding-right: 15px;
  }