.NavegadorTradicional {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.NavegadorTradicional-linkOption {
    width: -moz-fit-content;
    width: -moz-fit-content;
    border: 2px solid white;
    width: fit-content;
    transition: all .1s;
    margin-top: 10px;
    background-color: white;
    cursor: pointer;
}

.NavegadorTradicional-linkOption:hover {
    /* transform: translateY(-5px); */
    transform: translateX(20px);
    border: 2px solid #f7f7f7;
    background-color: #f7f7f7;
    border-radius: 10px;
}

.NavegadorTradicional-linkText {
    width: auto;
    font-size: 20px;
    color: #00007a;
    font-weight: 500;
}

.NavegadorTradicional-linkDescription {
    margin-left: 20px;
    color: #565656;
    font-size: 15px;
    font-weight: 400;
}

.NavegadorTradicional-searchContainer {
    width: 100%;
    height: 100%;
    display: flex;
}

.NavegadorTradicional-backButton {
    /* width: 5%; */
    height: 20%;
}

.NavegadorTradicional-graphSection {
    width: 80%;
    flex-direction: row;
    justify-content: space-around;
    display: flex;
    align-items: center;
}

.center-col {
    
   
    overflow-y: scroll;
    height: 75vh;
  }