.pantallaarchivos-back {
  height: 100%;
}

.pantallaarchivos-salida {
  margin: auto;
  text-align: center;
  text-decoration: underline;
  margin-left: 10px;
  margin-right: 10px;
  color:red;
  border: 2px red solid;
  border-radius: 10px;
  margin-bottom: 10px;
}

.pantallaarchivos-exteriordiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.listaopciones-back {
  margin-left: 10%;
  margin-top: 50px;
}

.listaopciones-button {
  appearance: none;
}

.listaopciones-button:checked + .listaopciones-text {
  color: #fff;
  width: 90%;
  background-color: #f91616;
  font-size: 20px;
  border: 1px solid #f91616;
}

.listaopciones-button:checked:hover + .listaopciones-text {
  color: #fff;
  width: 90%;
  background-color: #f91616;
  font-size: 20px;
  border: 1px solid #f91616;

}

.listaopciones-button:hover + .listaopciones-text {
  background-color: #ff9595;
  color: black;
  border: 1px solid #cfcfcf;
  font-size: 20px;
  width: 90%;
}

.listaopciones-button + .listaopciones-text {
  transition: all 0.3s ease 0s;
  border-radius: 25px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 7px;
  padding-right: 7px;
}

.listaopciones-text{
  color: var(--color-text-background);
  transition: color 300ms;
  border: 1px solid #bbbbbb;
  font-size: 20px;
  width: 90%;
}